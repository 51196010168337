import { bindable } from 'aurelia-framework';

export class DraggableHandle {
    @bindable key = 'lpfn-draggable-handle-key';

    onMousedown() {
        this.mouseClass = 'lpfn-dragging';
    }

    onMouseup() {
        this.mouseClass = '';
    }
}